import React, { useState } from 'react'
import { compose } from 'redux'
import { useHistory, useLocation } from 'react-router-dom'
import { checkIfAccountExists } from '../../store/session'
import withForm from 'constructicon/with-form'
import form from './form'
import { useSelector, useDispatch } from 'react-redux'

import Form from '../../../../../common/components/Form'
import InputField from '../../../../../common/components/InputField'
import LoadingMessage from '../../../../../common/components/LoadingMessage'
import Message from '../Message'

const CheckAccount = ({ form, formComponent }) => {
  const [errors, setErrors] = useState([])
  const history = useHistory()
  const location = useLocation()
  const status = useSelector(state => state.session.status)
  const dispatch = useDispatch()

  const handleCheckAccount = (email) => dispatch(checkIfAccountExists(email))

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      const data = await form.submit()
      const exists = await handleCheckAccount(data.email)
      const searchParams = new URLSearchParams(location.search)
      searchParams.set('message', 'true')
      history.push(`${exists ? '/sign_in' : '/sign_up'}?${searchParams.toString()}`)
    } catch (error) {
      setErrors([error.message])
    }
  }

  return (
    <LoadingMessage
      message='Checking your account, this may take a few seconds'
      status={status}
    >
      <Form
        isLoading={status === 'fetching'}
        onSubmit={handleSubmit}
        errors={errors}
        noValidate
        {...formComponent}
      >
        <Message show>
          Our online fundraising pages are now powered by JustGiving. To participate, you will need to create a new account or log in to an existing account. Let's check if you have a JustGiving account.
        </Message>
        <InputField {...form.fields.email} />
      </Form>
    </LoadingMessage>
  )
}

export default compose(
  withForm(form)
)(CheckAccount)
