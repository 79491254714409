import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Container from 'constructicon/container'
import Grid from 'constructicon/grid'
import GridColumn from 'constructicon/grid-column'
import RichText from 'constructicon/rich-text'

const DefaultWrapper = ({ application = {}, classNames, children, styles }) => {
  return (
    <Container styles={styles.wrapper}>
      <Grid spacing={1} align='center'>
        <GridColumn sm={4.5} md={5.5}>
          <Container styles={styles.blurb}>
            <img className={classNames.logo} src={application.logo_url} />
            <RichText styles={styles.copy}>
              <div dangerouslySetInnerHTML={{ __html: application.registration_copy }} />
              <p>
                Our online fundraising pages are powered by{' '}
                <a href='https://justgiving.com/' target='_blank' rel='noopener noreferrer'>JustGiving</a>
                , so please create or login to your account here.
              </p>
            </RichText>
          </Container>
        </GridColumn>
        <GridColumn sm={7.5} md={6.5}>
          {children}
        </GridColumn>
      </Grid>
    </Container>
  )
}
const mapState = ({ config }) => ({ ...config })

export default compose(
  connect(mapState),
  withStyles(styles)
)(DefaultWrapper)
