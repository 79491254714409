import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import AuthForm from '../../components/AuthForm'
import ResetPasswordForm from '../../components/ResetPasswordForm'
import AppLayout from '../../components/AppLayout'

const ResetPassword = () => {
  const location = useLocation()

  return (
    <AppLayout>
      <AuthForm
        heading='Reset Your JustGiving Password'
        formComponent={ResetPasswordForm}
        footer={
          <Link to={{
            pathname: '/sign_up',
            search: location.search
          }}
          >
            Need an account?
          </Link>
        }
      />
    </AppLayout>
  )
}

export default ResetPassword
