import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

const Tabs = ({ classNames }) => {
  const location = useLocation()
  const query = queryString.parse(location.search)

  return (
    <nav className={classNames.root}>
      <NavLink
        className={classNames.link}
        activeClassName={classNames.active}
        to={{
          pathname: '/sign_up',
          search: queryString.stringify(query)
        }}
      >
        Register
      </NavLink>
      <NavLink
        className={classNames.link}
        activeClassName={classNames.active}
        to={{
          pathname: '/sign_in',
          search: queryString.stringify(query)
        }}
      >
        Login
      </NavLink>
    </nav>
  )
}

export default withStyles(styles)(Tabs)
