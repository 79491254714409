import wristbandBackground from './wristband_background.jpg'

export default ({ application = {} }, { colors, mediaQuery, scale, rhythm }) => {
  return {
    wrapper: {
      outer: {
        position: 'relative',
        backgroundImage: `url(${wristbandBackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        ':before': {
          content: '""',
          position: 'absolute',
          zIndex: 0,
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,

          [mediaQuery('sm')]: {
            position: 'fixed',
            right: 'auto',
            width: '75%'
          }
        }
      },
      root: {
        display: 'flex',
        position: 'relative',
        padding: rhythm([1, 0.5]),
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: rhythm(24),
        minHeight: '100vh',
        [mediaQuery('sm')]: {
          padding: rhythm([1, 1.5]),
          maxWidth: rhythm(52)
        }
      }
    },
    blurb: {
      root: {
        padding: rhythm([0, 0.5]),
        [mediaQuery('sm')]: {
          padding: 0
        }
      }
    }
  }
}
