import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import AuthForm from '../../components/AuthForm'
import LoginForm from '../../components/LoginForm'
import AppLayout from '../../components/AppLayout'

const Login = () => {
  const location = useLocation()

  return (
    <AppLayout>
      <AuthForm
        heading='Parent/Guardian Login'
        formComponent={LoginForm}
        footer={
          <span>
            <Link to={{
              pathname: '/sign_up',
              search: location.search
            }}
            >
              Need an account?
            </Link>{' '}|{' '}
            <Link to={{
              pathname: '/sign_in/reset_password',
              search: location.search
            }}
            >
              Forgot your password?
            </Link>
          </span>
        }
      />
    </AppLayout>
  )
}

export default Login
