import React from 'react'
import { compose } from 'redux'
import { buildDonateUrl } from '../../../../../common/lib/donateUrl'
import withForm from 'constructicon/with-form'
import withStyles from 'constructicon/with-styles'
import form from './form'
import styles from './styles'

import Button from '../../../../../common/components/Button'
import Grid from 'constructicon/grid'
import GridColumn from 'constructicon/grid-column'
import Icon from '../../../../../common/components/Icon'
import InputField from '../../../../../common/components/InputField'
import Section from '../../../../../common/components/Section'

const DonationBlock = ({ child, classNames, form, loggedIn, styles }) => {
  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      await form.submit()
      window.location.href = `${buildDonateUrl(child, loggedIn)}&amount=${form.values.amount}`
    } catch (error) {
      console.error('Form submission failed:', error)
    }
  }

  return (
    <Section
      background='secondary'
      dark
      heading={`Donate to ${child.firstname}`}
      noBodyPadding
    >
      <Grid align='stretch' styles={styles.handles}>
        {[25, 50, 100].map((amount) => (
          <GridColumn key={amount} sm={3}>
            <a
              className={classNames.handle}
              href={buildDonateUrl(child, loggedIn, amount)}
            >
              <div className={classNames.label}>Donate</div>
              <div className={classNames.amount}>${amount}</div>
            </a>
          </GridColumn>
        ))}
        <GridColumn sm={3}>
          <form
            action={buildDonateUrl(child, loggedIn)}
            className={classNames.form}
            onSubmit={handleSubmit}
          >
            <div className={classNames.field}>
              <Icon color='secondary' name='dollar' />
              <input type='hidden' name='fundraiserName' value={child.firstname} />
              {loggedIn && <input type='hidden' name='reference' value={child.id} />}
              <InputField {...form.fields.amount} styles={styles.input} />
            </div>
            <Button
              block
              spacing={0.33}
              theme='secondary'
              type='submit'
            >
              {form.values.amount > 0
                ? `Donate $${form.values.amount}`
                : 'Donate'}
            </Button>
          </form>
        </GridColumn>
      </Grid>
    </Section>
  )
}

export default compose(withStyles(styles), withForm(form))(DonationBlock)
