import { useEffect, useRef } from 'react'
import QRCodeStyling from 'qr-code-styling'
import { colors } from '../traits'

const useQRCode = (url, options = { width: 100, height: 100 }) => {
  const qrCodeRef = useRef(null)
  const qrCode = useRef(null)

  useEffect(() => {
    qrCode.current = new QRCodeStyling({
      type: 'png',
      width: options.width,
      height: options.height,
      data: url,
      margin: 0,
      dotsOptions: {
        type: 'square',
        color: '#ffffff'
      },
      backgroundOptions: {
        round: 0,
        color: colors.secondary
      },
      cornersSquareOptions: {
        type: 'square',
        color: '#ffffff'
      },
      cornersDotOptions: {
        type: 'square',
        color: '#fafafa'
      },
      ...options
    })

    return () => {
      if (qrCode.current) {
        qrCode.current = null
      }
    }
  }, [])

  useEffect(() => {
    const container = qrCodeRef.current
    if (container && qrCode.current) {
      container.innerHTML = ''
      qrCode.current.append(container)
    }

    return () => {
      if (container) {
        container.innerHTML = ''
      }
    }
  }, [qrCodeRef.current, qrCode.current])

  useEffect(() => {
    if (url && qrCode.current) {
      qrCode.current.update({ data: url })
    }
  }, [url])

  return { qrCodeRef }
}

export default useQRCode
