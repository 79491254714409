import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import wristbandSplash from './wristband_splash.png'

const WristbandSplash = ({ classNames }) => (
  <div className={classNames.root}>
    <img
      src={wristbandSplash}
      alt='Wristband'
      className={classNames.image}
    />
    <div className={classNames.textOverlay}>
      <h1 className={classNames.heading}>WOOHOOOO! YOU CAN WIN $500 WORTH OF EXTRA PRIZE CREDITS!!!</h1>
      <p className={classNames.subtext}>Simply sign up or login<br /> then enter your code.</p>
    </div>
  </div>
)

export default withStyles(styles)(WristbandSplash)
