import React, { useState } from 'react'
import get from 'lodash/get'
import { compose } from 'redux'
import { connect, useDispatch, useSelector } from 'react-redux'
import { loginUser } from '../../store/session'
import withForm from 'constructicon/with-form'
import form from './form'

import Form from '../../../../../common/components/Form'
import InputField from '../../../../../common/components/InputField'
import LoadingMessage from '../../../../../common/components/LoadingMessage'
import Message from '../Message'
import useQuery from '../../../../../common/hooks/useQuery'

const parseErrors = (error) => {
  return get(error, 'response.data.Errors', [])
    .map((err) => err?.ErrorMessage)
    .filter(Boolean)
}

const LoginForm = ({ form, formComponent }) => {
  const [errors, setErrors] = useState([])
  const dispatch = useDispatch()
  const status = useSelector((state) => state.session.status)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setErrors([])

    try {
      const data = await form.submit()
      await dispatch(loginUser(data))
    } catch (error) {
      const errorMessages = parseErrors(error)
      setErrors(errorMessages)
    }
  }

  form.fields.password = form.fields.pField
  const { message: messageFromQuery } = useQuery()

  return (
    <LoadingMessage
      message='Logging into your account, this may take a few seconds'
      status={status}
    >
      <Form
        isLoading={status === 'fetching'}
        onSubmit={handleSubmit}
        errors={errors}
        noValidate
        {...formComponent}
      >
        <Message show={messageFromQuery}>
          We have determined you have an existing JustGiving account, please login
          to your account below.
        </Message>
        <InputField {...form.fields.email} />
        <InputField {...form.fields.password} />
      </Form>
    </LoadingMessage>
  )
}

const mapState = ({ session }) => ({
  email: session.email
})

export default compose(
  connect(mapState),
  withForm(form)
)(LoginForm)
