import React, { useState, useEffect } from 'react'
import axios from 'axios'
import DonationFeed from '../../../../../common/components/DonationFeed'

const Donations = ({ application, child, baseUrl, page }) => {
  const [data, setData] = useState([])
  const [status, setStatus] = useState('fetching')

  useEffect(() => {
    const apiClient = axios.create({ baseURL: baseUrl })

    apiClient
      .get(`/justgiving/api/v1/fundraising/pages/${page.slug}/donations`)
      .then(({ data }) => {
        setData(data)
        setStatus('fetched')
      })
      .catch((error) => {
        setStatus('failed')
        console.error('Failed to fetch donations:', error)
      })
  }, [page.slug, baseUrl])

  const manualDonations = [
    child.bonus_amount_in_cents > 0 && {
      isBonus: true,
      image: application.logo_avatar_url,
      name: 'School-Fun-Run',
      message: application.bonus_amount_message,
      amount: child.bonus_amount_in_cents / 100
    },
    child.adjustment_amount_in_cents > 0 && {
      image: application.logo_avatar_url,
      name: 'Admin',
      message: 'Online amount adjustment',
      amount: child.adjustment_amount_in_cents / 100
    }
  ].filter(Boolean)

  return (
    <DonationFeed
      manualDonations={manualDonations}
      child={child}
      data={data}
      hideSurnames
      status={status}
    />
  )
}

export default Donations
