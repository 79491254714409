import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import AuthForm from '../../components/AuthForm'
import SignupForm from '../../components/SignupForm'
import AppLayout from '../../components/AppLayout'

const Signup = () => {
  const location = useLocation()

  return (
    <AppLayout>
      <AuthForm
        heading='Parent/Guardian Registration'
        formComponent={SignupForm}
        footer={
          <Link to={{
            pathname: '/sign_in',
            search: location.search
          }}
          >
            Already have an account?
          </Link>
          }
      />
    </AppLayout>
  )
}

export default Signup
