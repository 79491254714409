import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Container from 'constructicon/container'
import Grid from 'constructicon/grid'
import GridColumn from 'constructicon/grid-column'
import WristbandSplash from '../WristbandSpash'

const WristbandWrapper = ({ children, styles }) => {
  return (
    <Container styles={styles.wrapper}>
      <Grid spacing={1} align='center'>
        <GridColumn sm={4.5} md={6} lg={6.5}>
          <Container styles={styles.blurb}>
            <WristbandSplash />
          </Container>
        </GridColumn>
        <GridColumn sm={7.5} md={6} lg={5.5}>
          {children}
        </GridColumn>
      </Grid>
    </Container>
  )
}

export default withStyles(styles)(WristbandWrapper)
