import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { sendPasswordResetEmail } from '../../store/session'
import { Link, useLocation } from 'react-router-dom'
import withForm from 'constructicon/with-form'
import form from './form'

import Button from '../../../../../common/components/Button'
import Container from 'constructicon/container'
import Form from '../../../../../common/components/Form'
import InputField from '../../../../../common/components/InputField'
import LoadingMessage from '../../../../../common/components/LoadingMessage'
import RichText from 'constructicon/rich-text'

const ResetPasswordForm = ({ baseUrl, form, formComponent }) => {
  const [errors, setErrors] = useState([])
  const [status, setStatus] = useState('')
  const location = useLocation()
  const dispatch = useDispatch()

  const handleSubmit = async (e) => {
    e.preventDefault()

    const { email } = await form.submit()

    try {
      setStatus('sending')
      await dispatch(sendPasswordResetEmail({
        email,
        returnTo: `${baseUrl}users/sign_in`
      }))

      form.resetForm()
      setStatus('sent')
    } catch (error) {
      setErrors(error.response?.data?.Errors || [])
    }
  }

  if (status === 'sent') {
    return (
      <div>
        <p>
          If an account exists with this email address, you will receive an
          email within the next few minutes. It contains instructions for
          changing your password.
        </p>
        <Container spacing={{ t: 0.5, b: 1 }}>
          <Button
            theme='secondary'
            onClick={() => setStatus('')}
          >
            Send to a different email
          </Button>
        </Container>
        <RichText size={-1} spacing={{ t: 1 }}>
          <Link
            to={{
              pathname: '/sign_in',
              search: location.search
            }}
          >
            Login to your account
          </Link>
        </RichText>
      </div>
    )
  }

  return (
    <LoadingMessage
      message='Sending you a password reset email'
      status={status}
    >
      <Form
        isLoading={status === 'sending'}
        onSubmit={handleSubmit}
        errors={errors}
        submit='Send Instructions'
        noValidate
        {...formComponent}
      >
        <InputField {...form.fields.email} />
      </Form>
    </LoadingMessage>
  )
}

export default withForm(form)(ResetPasswordForm)
