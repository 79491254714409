import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clearFlash } from '../../store/flash'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Flash from '../../../../../common/components/Flash'
import Section from '../../../../../common/components/Section'
import useQuery from '../../../../../common/hooks/useQuery'
import Tabs from '../Tabs'
import DefaultWrapper from '../DefaultWrapper'
import WristbandWrapper from '../WristbandWrapper'

const AppLayout = ({ children, styles, location }) => {
  const { landing } = useQuery()
  const flash = useSelector(state => state.flash)
  const dispatch = useDispatch()
  const Wrapper = landing === 'wristband' ? WristbandWrapper : DefaultWrapper

  return (
    <Wrapper>
      <Section styles={styles.section} header={<Tabs />}>
        <div>{children}</div>
      </Section>
      <Flash {...flash} clearFlash={() => dispatch(clearFlash())} />
    </Wrapper>
  )
}

export default withStyles(styles)(AppLayout)
