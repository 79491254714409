import React from 'react'
import { compose } from 'redux'
import withTraits from '../../../common/lib/withTraits'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Container from 'constructicon/container'
import Button from '../../../common/components/Button'

import funzyGiftCards from './SFR25_THANKYOU_GIFTCARDS.png'
import funzyLogo from './FUNZY_LOGO_WEB_TEAL_B_F.png'
import funzyCarKeys from './SFR25_THANKYOU_CARKEYS.png'

const ThankYouPage = ({
  amount,
  application,
  child = {},
  classNames,
  donor_name: donorName,
  page_url: pageUrl,
  status,
  styles
}) => (
  <Container width={24} spacing={1} styles={styles.container}>
    <a className={classNames.logo} href='/'>
      <img alt={application.application_title} src={application.logo_url} />
    </a>

    <div className={classNames.card}>
      <div className={classNames.header}>
        <h1 className={classNames.title}>THANK YOU</h1>
        <h2 className={classNames.subtitle}>FOR YOUR KIND DONATION AND SUPPORTING <br /> {application.application_title}</h2>
      </div>

      <div className={classNames.funzySection}>
        <div className={classNames.funzyImagesRow}>
          <div className={classNames.funzyRewards}>
            <img src={funzyGiftCards} alt='FUNZY Rewards' className={classNames.funzyGiftCards} />
          </div>
          <div className={classNames.funzyRewards}>
            <img src={funzyLogo} alt='FUNZY Rewards' className={classNames.funzyLogo} />
          </div>
          <div className={classNames.funzyRewards}>
            <img src={funzyCarKeys} alt='Car Keys' className={classNames.funzyCarKeys} />
          </div>
        </div>
        <div className={classNames.funzyContent}>
          <div className={classNames.funzyText}>
            <p>We'd like to thank you with <strong>3 MONTHS FREE</strong> at FUNZY <br />
              - a rewards club for awesome people like yourself!
            </p>
          </div>

          <div className={classNames.funzyBenefits}>
            <ul>
              <li>Access to discounts from places such as Mattel, JB Hi-Fi and Experience OZ!</li>
              <li>The chance to WIN $50 a day, $1,000 per week, $10,000 per term and a car a year!</li>
              <li>Massively discounted toys - save hundreds!</li>
              <li>No Credit Card required to join - this is our way of saying THANK YOU for donating!</li>
            </ul>
          </div>
          <div className={classNames.funzyCode}>
            <p>Use the code: <strong className={classNames.promoCode}>Funzy25</strong></p>
            <p>To get 3 MONTHS FREE FUNZY MEMBERSHIP!</p>
          </div>
          <div className={classNames.buttonWrapper}>
            <a href='https://funzy.com.au' target='_blank' rel='noopener noreferrer' className={classNames.funzyButton}>
              FUNZY.COM.AU
            </a>
          </div>
        </div>
      </div>

      {pageUrl && (
        <div className={classNames.backSection}>
          <Button theme='orange' tag='a' href={pageUrl}>
            BACK TO CHILD'S PAGE
          </Button>
          <small className={classNames.info}>
            <strong>Please Note:</strong> Your donation may take up to 2 minutes <br /> to appear in the activity feed on child's profile page.
          </small>
        </div>
      )}
    </div>
  </Container>
)

export default compose(
  withTraits,
  withStyles(styles)
)(ThankYouPage)
