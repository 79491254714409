export default (props, { rhythm, colors, mediaQuery }) => {
  return {
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '-20px',
      marginRight: '100px',
      position: 'relative',
      width: '100%',
      [mediaQuery('sm')]: {
        marginBottom: rhythm(1)
      }
    },
    image: {
      width: '100%',
      maxWidth: '250px',
      display: 'block',
      [mediaQuery('sm')]: {
        maxWidth: '100%',
        height: 'auto'
      }
    },
    textOverlay: {
      position: 'absolute',
      top: '65%',
      left: '50%',
      transform: 'translateX(-50%)',
      [mediaQuery('md')]: {
        top: '65%'
      },
      textAlign: 'center',
      color: colors.light,
      zIndex: 1
    },
    heading: {
      fontSize: '0.6rem',
      width: '150px',
      fontWeight: 800,
      marginBottom: '0.2rem !important',
      [mediaQuery('sm')]: {
        fontSize: '0.6rem'
      },
      [mediaQuery('md')]: {
        width: '230px',
        fontSize: '0.9rem'
      },
      [mediaQuery('lg')]: {
        width: '300px',
        fontSize: '1.2rem'
      }
    },
    subtext: {
      fontSize: '0.5rem',
      marginTop: '0rem !important',
      fontWeight: 700,
      lineHeight: '0.6rem',
      [mediaQuery('sm')]: {
        fontSize: '0.5rem',
        lineHeight: '0.5rem'
      },
      [mediaQuery('md')]: {
        fontSize: '0.8rem',
        lineHeight: '1rem'
      },
      [mediaQuery('lg')]: {
        fontSize: '1rem',
        lineHeight: '1.1rem'
      }
    }
  }
}
