import React from 'react'
import * as traits from '../../traits'
import TraitsProvider from 'constructicon/traits-provider'

export const withTraits = Component => (props) => {
  return (
    <TraitsProvider traits={traits}>
      <Component {...props} />
    </TraitsProvider>
  )
}

export default withTraits
