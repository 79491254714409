import React, { useState } from 'react'
import get from 'lodash/get'
import { compose } from 'redux'
import { connect, useSelector, useDispatch } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import withForm from 'constructicon/with-form'
import withStyles from 'constructicon/with-styles'
import withToggle from 'constructicon/with-toggle'
import styles from './styles'
import form from './form'
import queryString from 'query-string'
import { signupUser } from '../../store/session'
import Form from '../../../../../common/components/Form'
import Grid from 'constructicon/grid'
import GridColumn from 'constructicon/grid-column'
import InputField from '../../../../../common/components/InputField'
import LoadingMessage from '../../../../../common/components/LoadingMessage'
import TermsAndConditions from '../../../../../common/components/TermsAndConditions'
import Message from '../Message'

const EmailInUseMessage = () => {
  const location = useLocation()

  return (
    <span>
      There is already a JustGiving account associated with this email.
      If you do not remember your password you can{' '}
      <Link to={{
        pathname: '/sign_in/reset_password',
        search: location.search
      }}
      >
        reset it here
      </Link>.
    </span>
  )
}

const SignupForm = ({
  form,
  onToggleOff,
  toggled,
  formComponent,
  styles,
  application
}) => {
  const [errors, setErrors] = useState([])
  const location = useLocation()
  const status = useSelector(state => state.session.status)
  const dispatch = useDispatch()

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      const data = await form.submit()
      await dispatch(signupUser({
        ...data,
        country: application.country_name
      }))
    } catch (error) {
      const errors = get(error, 'response.data.Errors', [])
        .map(err => err?.ErrorMessage)
        .map(message => {
          return message.includes('Email address is in use.')
            ? <EmailInUseMessage />
            : message
        })
      setErrors(errors)
    }
  }

  const acceptSFRTerms = () => {
    form.fields.sfrTerms.onChange(true)
    onToggleOff()
  }

  const pwErrorStyles = {
    error: {
      '@media(min-width: 768px)': {
        width: form.fields.passwordConfirmation.error ? 'inherit' : 'calc(200% + 1.5rem)'
      }
    }
  }

  form.fields.password = form.fields.pField

  return (
    <LoadingMessage message='Creating your account, this may take a few seconds' status={status}>
      <Form
        isLoading={status === 'fetching'}
        onSubmit={handleSubmit}
        errors={errors}
        noValidate
        autoComplete='off'
        {...formComponent}
      >
        <Message show={queryString.parse(location.search).message}>
          We have determined you do not have an existing JustGiving account, please create your account below.
        </Message>
        <Grid spacing={{ x: 0.5 }}>
          <GridColumn sm={6}><InputField {...form.fields.firstName} /></GridColumn>
          <GridColumn sm={6}><InputField {...form.fields.lastName} /></GridColumn>
        </Grid>

        <InputField {...form.fields.email} />
        <InputField {...form.fields.emailConfirmation} />

        <Grid spacing={{ x: 0.5 }}>
          <GridColumn sm={6}><InputField {...form.fields.password} styles={pwErrorStyles} /></GridColumn>
          <GridColumn sm={6}><InputField {...form.fields.passwordConfirmation} /></GridColumn>
        </Grid>

        <InputField {...form.fields.sfrTerms} />
        <InputField {...form.fields.jgTerms} />
        <InputField {...form.fields.newsletter} />
      </Form>
      {toggled && (
        <TermsAndConditions
          acceptTerms={acceptSFRTerms}
          handleClose={onToggleOff}
          currentApplication={application}
        />
      )}
    </LoadingMessage>
  )
}

const mapState = ({ config, session }) => ({
  application: config.application,
  email: session.email
})

export default compose(
  connect(mapState),
  withToggle,
  withStyles(styles),
  withForm(form)
)(SignupForm)
