export default (props, { colors, rhythm, scale, mediaQuery }) => ({
  container: {
    root: {
      textAlign: 'center',
      backgroundColor: '#2cdec6',
      minHeight: '100vh',
      minWidth: '100vw',
      margin: '-8px',
      padding: rhythm(1),
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      overflowY: 'auto',
      [mediaQuery('sm')]: {
        padding: rhythm(2)
      }
    }
  },
  logo: {
    display: 'block',
    maxWidth: rhythm(8),
    margin: 'auto',
    marginBottom: rhythm(1),
    [mediaQuery('sm')]: {
      maxWidth: rhythm(10)
    },
    img: {
      display: 'block',
      maxWidth: '100%'
    }
  },
  card: {
    background: colors.light,
    padding: 0,
    margin: '0 auto',
    maxWidth: '480px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden'
  },
  header: {
    background: '#e3e3e3',
    paddingTop: rhythm(0.5),
    paddingBottom: rhythm(0.5)
  },
  title: {
    margin: '0 auto',
    fontSize: '2.75rem',
    fontWeight: 900,
    lineHeight: '1em',
    color: '#000',
    [mediaQuery('sm')]: {
      fontSize: '4rem'
    }
  },
  subtitle: {
    margin: '0 auto',
    fontSize: '0.675rem',
    fontWeight: 800,
    lineHeight: '1.4em',
    color: '#000',
    textTransform: 'uppercase',
    [mediaQuery('sm')]: {
      fontSize: '1rem'
    }
  },
  funzyContent: {
    maxWidth: '100%',
    margin: '0 auto',
    fontWeight: 700,
    textAlign: 'left',
    padding: rhythm([0, 0.75]),
    fontSize: '0.625rem',
    '> a': {
      marginTop: rhythm(1)
    },
    [mediaQuery('sm')]: {
      padding: rhythm([0, 2]),
      fontSize: '0.75rem'
    }
  },
  buttonWrapper: {
    textAlign: 'center',
    margin: rhythm([0.25, 0]),
    marginBottom: rhythm(0.5)
  },
  funzySection: {
  },
  funzyRewards: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:nth-child(2)': {
      flex: '0 0 45%',
      marginTop: rhythm(-1)
    }
  },
  funzyLogo: {
    width: '100%',
    maxWidth: rhythm(8),
    margin: '0 auto',
    [mediaQuery('sm')]: {
      maxWidth: rhythm(12)
    }
  },
  funzyText: {
    '> p': {
      margin: rhythm(0.25),
      lineHeight: '1.4em',
      strong: {
        fontWeight: 900
      }
    }
  },
  funzyBenefits: {
    textAlign: 'left',
    margin: rhythm(0.25),
    marginRight: rhythm(2.5),
    ul: {
      listStyle: 'none',
      margin: 0
    },
    li: {
      position: 'relative',
      paddingLeft: rhythm(0.5),
      lineHeight: '1.3em',
      listStyle: 'none',
      ':before': {
        content: '"•"',
        color: '#c041fb',
        position: 'absolute',
        left: rhythm(0),
        fontSize: '1.2em'
      }
    }
  },
  funzyCode: {
    textAlign: 'center',
    marginTop: rhythm(0.5),
    fontWeight: 800,
    fontSize: '0.8rem',
    '> p': {
      lineHeight: '1.3em',
      margin: '0rem -0.5rem'
    },
    [mediaQuery('sm')]: {
      fontSize: '1rem'
    }
  },
  promoCode: {
    color: '#c041fb',
    fontWeight: 900
  },
  funzyButton: {
    display: 'inline-block',
    padding: rhythm([0.375, 1.5]),
    backgroundColor: '#2bdec6',
    color: colors.light,
    textDecoration: 'none',
    borderRadius: '25px',
    fontWeight: 900,
    fontSize: '1.1rem',
    transition: 'background-color 0.2s ease',
    [mediaQuery('sm')]: {
      padding: rhythm([0.5, 2]),
      fontSize: '1.3rem'
    },
    ':hover': {
      backgroundColor: '#27c8b2'
    }
  },
  backSection: {
    borderTop: `1px solid ${colors.shade}`,
    padding: rhythm([0.5, 0]),
    textAlign: 'center',
    background: '#e3e3e3',
    '> a': {
      fontSize: '1.1rem',
      fontWeight: 800,
      borderRadius: '50px',
      [mediaQuery('sm')]: {
        fontSize: '1.1rem',
        padding: rhythm([0.5, 2.5])
      }
    }
  },
  info: {
    display: 'block',
    margin: `${rhythm(0.25)} auto 0`,
    maxWidth: rhythm(18),
    fontWeight: 500,
    fontSize: '0.575rem',
    lineHeight: '1.3em',
    color: '#000',
    padding: rhythm([0, 0.5]),
    [mediaQuery('sm')]: {
      fontSize: '0.65rem'
    }
  },
  funzyImagesRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: rhythm([0, 0]),
    [mediaQuery('sm')]: {
      margin: rhythm([0, 0]),
      padding: rhythm([0, 0.25])
    }
  },
  funzyGiftCards: {
    width: '100%',
    maxWidth: rhythm(4),
    margin: '0 auto',
    [mediaQuery('sm')]: {
      maxWidth: rhythm(6)
    }
  },
  funzyCarKeys: {
    width: '100%',
    maxWidth: rhythm(4),
    margin: '0 auto',
    [mediaQuery('sm')]: {
      maxWidth: rhythm(6)
    }
  }
})
